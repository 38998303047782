import { initialValues } from '~checkout/components/Cart/Purchaser/schema.js';
/**
 * Cart Store
 * ----------------------------
 *
 *
 */
export const useCartStore = defineStore('cart', () => {
    /**
     * Products
     * ------------------------------------------------------------------------------------------
     */
    const products = ref<Products>({});

    /**
     * Passengers
     * ------------------------------------------------------------------------------------------
     */
    const passengers = ref<Passenger[]>([]);

    /**
     * Offers (extracted from products)
     * ------------------------------------------------------------------------------------------
     */
    const offers = computed(() => {
        return Object.keys(products.value).reduce<Offers>((acc, key) => {
            const product = products.value[key as ProductKey];
            if (product && product.offer) {
                acc[key as ProductKey] = product.offer;
            }
            return acc;
        }, {});
    });

    /**
     * Purchaser
     * ------------------------------------------------------------------------------------------
     */
    const purchaser = ref<Purchaser>(deepClone(initialValues));

    const hasPurchaser = computed(() => {
        return !Object.values(purchaser.value).every((value) => {
            if (value !== null && typeof value === 'object') {
                return Object.values(value).every(nestedValue => nestedValue === '');
            }
            return value === '' || value === undefined || value === null;
        });
    });

    /**
     * Optional Offers
     * ------------------------------------------------------------------------------------------
     */
    const offersAncillary = ref<OfferAncillary[]>([]);
    const selectedOfferAncillaryRefs = ref<string[]>([]);
    const selectedOffersAncillary = computed(() => offersAncillary.value.filter(offer => selectedOfferAncillaryRefs.value.includes(offer.offerRef)));

    /**
     * Other
     * ------------------------------------------------------------------------------------------
     */
    const lastProductConfig = ref<ProductConfig>();

    /**
     * Quote
     * ------------------------------------------------------------------------------------------
     */
    const quote = ref<Quote>();
    const quoteIncludingOffersAncillary = ref<Quote>();

    // load quote for all offers.
    // Load quote for all offers without optional ancillary offers to get them returned as possible options.
    // Then load quote for all offers including additionals to get the final price.
    const loadQuote = useDebounceFn(loadQuoteFn, 100);
    async function loadQuoteFn() {
        // load offers without optional ancillary offers
        ({ data: {
            quote: quote.value,
            offersAncillary: offersAncillary.value,
        } } = await apiLoadCartQuote(Object.values(offers.value), passengers.value, []));

        // load offers with optional ancillary offers
        ({ data: {
            quote: quoteIncludingOffersAncillary.value,
        } } = await apiLoadCartQuote(Object.values(offers.value), passengers.value, selectedOffersAncillary.value));

        return true;
    }

    // If any of the offers (or selected optionals) change, reload the quote, so we always have up-to-date prices
    useWatchOnReady([offers, selectedOfferAncillaryRefs], () => {
        loadQuote();
    }, { deep: true });

    /**
     * ------------------------------------------------------------------------------------------
     */

    function reset() {
        products.value = {};
        passengers.value = [];
        purchaser.value = deepClone(initialValues);
        offersAncillary.value = [];
        selectedOfferAncillaryRefs.value = [];
        lastProductConfig.value = undefined;
        quote.value = undefined;
    }

    return {
        // Products
        products,

        // Passengers
        passengers,

        // Offers
        offers,

        // Purchaser
        purchaser,
        hasPurchaser,

        // Optional Offers
        offersAncillary,
        selectedOfferAncillaryRefs,
        selectedOffersAncillary,

        // Other
        lastProductConfig,

        // Quote
        quote,
        quoteIncludingOffersAncillary,
        loadQuote,

        reset,
    };
}, {
    persist: storePersist.storePersistOptions,
});
