interface ResultType {
    quote: Quote | undefined;
    offersAncillary: OfferAncillary[];
}
type ApiResponseType = ApiSchema['Quote'];
type ParamsType = ApiSchema['BookingRequest'];

/**
 * Loads the quote for the given offers and passengers in the cart.
 * The quote contains the final price for all given offers combined.
 * In the cart context, the quote also contains optional ancillary offers.
 *
 * You can pass optional ancillary offers to this function, which will be included in the quote and determine the final price.
 * Do note that if you pass optional ancillary offers, they will not be returned in the quote! So to get a list of available
 * optional ancillary offers, you mustn't include the already selected ones in the same request.
 */
export default async function apiLoadCartQuote(offers: Offer[], passengers: Passenger[], offersAncillary: OfferAncillary[]): Promise<ApiResult<ResultType>> {
    try {
        const optionalReservationSelections = offers
            .flatMap(o => o.offerParts as OfferPart[])
            .filter(op => op.type === 'reservation')
            .map(op => ({ reservationId: op.id })) ?? [];

        return await callApiPost<ResultType, ApiResponseType, ParamsType>(
            '/quotes',

            // Request body
            {
                offers: [
                    ...offers.map(o => ({
                        offerId: o.offerId,
                        passengerRefs: o.passengerRefs,
                        optionalReservationSelections,
                    })),
                    ...offersAncillary.map(o => ({
                        offerId: o.offerId,
                        passengerRefs: [],
                    })),
                ],
                passengerSpecifications: passengers.map(p => ({
                    externalRef: p.ref,
                    type: 'PERSON',
                    dateOfBirth: formatDateTimeISO(p.dateOfBirth),
                    cards: p.reductionCards ?? [],
                })),
            },
            // Transform result
            (response: ApiResponseType) => {
                const quote = transformQuote(response);
                const offersAncillary = transformOffersAncillary(response.additionalOptionalOffersBasedOnSelection ?? []);

                return {
                    quote,
                    offersAncillary,
                } satisfies ResultType;
            },
        );
    }
    catch (error) {
        handleError(error, {
            offers,
            passengers,
            offersAncillary,
        });
        return {
            ok: false,
            warnings: [],
            data: {
                quote: undefined,
                offersAncillary: [],
            } satisfies ResultType,
        };
    }
}

/**
 * Transform quote
 */
function transformQuote(response: ApiResponseType) {
    return {
        price: response.price,
    };
}
