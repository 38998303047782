/**
 * Gets all ancillary offers from a API offer
 *
 * @param offers The offers from the API
 * @returns all ancillary offers
 */
export function transformOffersAncillary(offers: ApiSchema['Offer'][]): OfferAncillary[] {
    return offers.reduce<OfferAncillary[]>((acc, offer) => {
        // If the offer is insurance
        if (offer.ancillaryOfferParts?.[0]?.type === 'INSURANCE') {
            const offerAncillary = transformOfferAncillaryInsurance(offer);
            add(acc, offerAncillary);
        }

        return acc;
    }, []) ?? [];
}

/**
 *
 */
function add(acc: OfferAncillary[], offerAncillary: OfferAncillary | undefined) {
    if (!offerAncillary) {
        return;
    }
    acc.push(offerAncillary);
}
