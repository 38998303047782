/**
 * Transfroms an ancillary insurance offer
 *
 * @param offer The offer containing the ancillaries
 * @returns The ancillary insurance offer
 */
export function transformOfferAncillaryInsurance(offer: ApiSchema['Offer']): OfferAncillary | undefined {
    const part = offer.ancillaryOfferParts?.[0];
    if (part) {
        return {
            offerId: offer.offerId,
            offerRef: part.type,
            price: part.price,
            type: 'insurance',
            name: part.summary ?? '',
            info: {
                heading: part.summary ?? '',
                body: part.description ?? '',
            },
        };
    }
    return undefined;
}
